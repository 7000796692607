<template>
    <div id="app">
        <div class="wrapper">
            <div class="container">
                <header id="header">
                    <div>
                        <!-- <input v-model="s" type="text" autofocus name="s" class="search-field" placeholder="Search..."
                            @change="checkForm"> -->
                        <input v-model="s" type="text" autofocus name="s" class="search-field" placeholder="Search..."
                            @change="checkForm">
                        <!-- Add the search button here -->
                        <button @click="checkForm" class="search-button">Search</button>
                        <a v-if="showHeader" class="icon_back" @click="back">
                            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 3.9 6.68">
                                <title>Back</title>
                                <path
                                    d="M173.4,474a.2.2,0,0,1-.06.15l-2.64,2.64,2.64,2.63a.2.2,0,0,1,.06.15.25.25,0,0,1-.06.16l-.34.33a.19.19,0,0,1-.15.07.21.21,0,0,1-.16-.07L169.57,477a.22.22,0,0,1-.06-.15.25.25,0,0,1,.06-.16l3.12-3.12a.26.26,0,0,1,.16-.07.23.23,0,0,1,.15.07l.34.34A.2.2,0,0,1,173.4,474Z"
                                    transform="translate(-169.51 -473.45)" />
                            </svg>
                        </a>
                        <router-link to="/" class="logo_nav">
                            <img :src="`${publicPath}weber-primary-logo.png`" width="100" alt="logo" />
                        </router-link>
                        <a v-if="showHeader" href="#" class="icon_search" @click.prevent="search">
                            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 11.14 11.14">
                                <title>Search</title>
                                <path
                                    d="M238.48,461.36a.83.83,0,0,1-.26.61.82.82,0,0,1-.6.25.79.79,0,0,1-.6-.25l-2.3-2.29a4.66,4.66,0,0,1-4.5.45,4.53,4.53,0,0,1-1.51-1,4.72,4.72,0,0,1-1-5.17,4.94,4.94,0,0,1,1-1.51,4.68,4.68,0,0,1,1.51-1,4.71,4.71,0,0,1,3.66,0,4.63,4.63,0,0,1,2.51,2.51,4.71,4.71,0,0,1-.46,4.5l2.3,2.3A.86.86,0,0,1,238.48,461.36Zm-4.31-3.45a3,3,0,1,0-5.12-2.12,3,3,0,0,0,5.12,2.12Z"
                                    transform="translate(-227.33 -451.08)" />
                            </svg>
                        </a>
                        <a v-if="showDownloadButton" href="#" class="icon_search icon_download"
                            @click.prevent="downloadCsv">
                            <span v-if="!downloading">CSV</span>
                            <div v-if="downloading == 'csv'" class="lds-ring">
                                <div />
                                <div />
                                <div />
                                <div />
                            </div>
                        </a>
                        <a v-if="showDownloadButton" href="#" class="icon_search icon_download icon_download2"
                            @click.prevent="downloadPdf">
                            <span v-if="!downloading">PDF</span>
                            <div v-if="downloading == 'pdf'" class="lds-ring">
                                <div />
                                <div />
                                <div />
                                <div />
                            </div>
                        </a>
                    </div>


                    <div v-if="filterSearchs" class="search-results">
                        <h3 class="search-results-title">Search results</h3>

                        <div class="search-results-close" @click="closeSearch">
                            <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 24 24"
                                fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z"
                                    fill="white" />
                            </svg>
                        </div>

                        <ul>
                            <li v-for="item in filterSearchs.data" :key="item.id" @click="search">
                                <span v-if="item.product.product_title_line_1">
                                    <router-link
                                        :to="{ path: '/' + countryCode + '/' + catalogType + '/barbecues/' + category + '/' + item.product.importId }"
                                        class="products-overview-search-results">
                                        {{ item.product?.product_title_line_1['value'] }} -
                                        {{ item.product?.product_title_line_2['value'] }} -
                                        {{ item.product?.product_title_line_3['value'] }} &#187;
                                    </router-link>
                                </span>
                                <span v-if="item.product.product_title_line_1_accessories">
                                    <router-link
                                        :to="{ path: '/' + countryCode + '/' + catalogType + '/Accessoires/' + item.product.importId }"
                                        class="products-overview-search-results">
                                        {{ item.product.product_title_line_1_accessories['value'] }} <span
                                            v-if="item.product.product_title_line_2_accessories">-
                                            {{ item.product.product_title_line_2_accessories['value'] }}</span> &#187;
                                    </router-link>
                                </span>
                            </li>
                        </ul>
                    </div>
                </header>
            </div>
        </div>
        <div v-if="isLoading" class="loading-new">
            <div class="lds-ring">
                <div />
                <div />
                <div />
                <div />
            </div>
        </div>
        <router-view :key="$route.fullPath"></router-view>
    </div>
</template>

<script>

import axios from 'axios';
import { useToast } from "vue-toastification";
import { mapState } from 'vuex';
import { store } from './store/store.js';

export default {
    name: 'App',
    setup() {
        // Get toast interface
        const toast = useToast();
        // Make it available inside methods
        return { toast }
    },
    data() {
        return {
            downloading: false,
            filterSearchs: null,
            catalogArray: [],
            publicationId: null,
            s: "",
            // eslint-disable-next-line
            publicPath: process.env.BASE_URL
        }
    },

    computed: {
        ...mapState([
            'countryId',
            'isLoading'
        ]),
        countryCode() {
            return this.$route.params.countryCode
        },
        countryId() {
            return store.state.countries.find(country => country.code === this.countryCode)?.id || null
        },
        category() {
            return this.$route.params.categoryName
        },
        categoryId() {
            const category = store.state.categories.find(category => category.code === this.category);
            return category ? category.id : null;
        },
        catalogType() {
            return this.$route.params.catalogType
        },
        showHeader() {
            return this.$route.path !== '/'
        },
        showDownloadButton() {
            return Object.keys(this.$route.params).includes('categoryName')
        },
        importId() {
            return this.$route.params.importId
        }
    },
    watch: {
        '$route': {
            immediate: true, // This means the watcher will also trigger on component creation
            async handler() {
                await store.dispatch('setCountryCode', this.countryCode)
                await store.dispatch('setCountryId', {
                    countryCode: this.countryCode
                })
                if (store.state.categories.length === 0 && this.countryCode && this.catalogType) {
                    await store.dispatch('setCategories', {
                        countryCode: this.countryCode,
                        catalogType: this.catalogType
                    });
                }
            }
        },
    },
    mounted() {
        document.addEventListener('click', this.handleOutsideClick);
    },
    methods: {
        // Method to handle clicks on document body
        handleOutsideClick(event) {
            const searchField = document.querySelector('#header');
            const isClickInsideSearchField = searchField.contains(event.target);
            if (!isClickInsideSearchField && document.getElementById('header').classList.contains('search')) {
                console.log('Closing search');
                this.toggleSearch();
            }
        },
        checkForm: function () {
            axios
                .get(store.state.apiUrl + '/cataloglinessearch/' + this.countryId + '/' + this.s)
                .then(
                    (response) => {
                        if (response.status !== 200) {
                            // Show toast message for error
                            this.toast.error("There was an error fetching the search results", {
                                position: "bottom-right",
                            });
                        }
                        if (response.data.length === 0) {
                            // Show toast message for no results
                            this.toast.error("No results found... Please try another query", {
                                position: "bottom-right",
                            });
                            return;
                        }
                        if (response.data.length > 0 && response.status === 200) {
                            this.filterSearchs = response;
                            console.log(response)
                        }
                    })
                .catch((error) => {
                    console.log(error);
                    // Show toast message for error
                    this.toast.error("There was an error fetching the search results", {
                        position: "bottom-right",
                    });
                });
        },
        back() {
            this.$router.go(-1)
        },
        search() {
            document.getElementById('header').classList.toggle('search')
            document.getElementsByClassName('search-field')[0].focus();
            this.filterSearchs = null
        },
        toggleSearch() {
            // Toggles the search field visibility
            const header = document.getElementById('header');
            if (header.classList.contains('search')) {
                header.classList.remove('search');
            } else {
                header.classList.add('search');
                document.getElementsByClassName('search-button').toggle();
                document.getElementsByClassName('search-field')[0].focus();
            }
            this.filterSearchs = null;
        },
        closeSearch() {
            document.getElementById('header').classList.toggle('search')
            this.filterSearchs = null
        },
        async downloadCsv() {
            this.downloading = 'csv';

            // if no importID, we're on a category page, so download category CSV
            if (this.importId === undefined) {
                // category CSV download
                var categoryDownloadURL = store.state.apiUrl + '/download/csv-publication/' + this.countryCode + '/' + this.category + '/' + this.catalogType;

                axios({
                    url: categoryDownloadURL,
                    method: 'GET',
                    responseType: 'blob', // important
                }).then((response) => {
                    this.download(response, 'Digital Premium_' + this.$route.params.categoryName + '.csv');
                    // window.open(categoryDownloadURL);
                    this.downloading = false;
                });

            } else {
                // Single product download
                axios({
                    url: `${store.state.apiUrl}/download/csv/${this.countryCode}/${this.catalogType}/${this.importId}`,
                    method: 'GET',
                    responseType: 'blob', // important
                }).then((response) => {
                    this.download(response, 'Product ' + this.importId + '.csv');
                    this.downloading = false;
                });
            }

        },
        async downloadPdf() {
            this.downloading = 'pdf';
            console.log(this.importId, this.product)
            var catalogId = store.state.catalogs.find((c) => c.name.toLowerCase().includes(this.$route.params.catalogType));
            // if no ID, we're on a category page, so download category PDF
            if (this.importId === undefined) {
                //category download
                var categoryDownloadURL = store.state.downloadUrl + '/pdf/publication/' + catalogId.id + '/' + this.categoryId;
                // open category PDF in new tab
                window.open(categoryDownloadURL);
                this.downloading = false;
                // axios({
                //     url: categoryDownloadURL,
                //     method: 'GET',
                //     responseType: 'blob', // important
                // }).then((response) => {
                //     this.download(response, 'Digital Catalogue_' + this.$route.params.categoryName + '.pdf');
                //     this.downloading = false;
                // });

            } else {
                // Single product download
                var productDownloadURL = store.state.downloadUrl + '/pdf/line/' + catalogId.id + '/' + this.importId;
                console.log(productDownloadURL)
                // open product PDF in new tab
                window.open(productDownloadURL);
                this.downloading = false;
                axios({
                    // url: `${store.state.apiUrl}/download/pdf/${this.countryCode}/${this.catalogType}/${this.importId}`,
                    // url: `https://test.onlinedatabasetool.com/pdf/${this.countryCode}/${this.catalogType}/${this.importId}`,
                    // url: `https://onlinedatabasetool.com/download/pdf/${this.countryCode}/${this.catalogType}/-48294`,
                    url: productDownloadURL,
                    method: 'GET',
                    // responseType: 'blob', // important
                }).then((response) => {
                    this.download(response, 'Product ' + this.importId + '.pdf');
                    this.downloading = false;
                });
            }
        },
        download(response, fileName) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            URL.revokeObjectURL(url)
            this.downloading = false;
        }
    },
}
</script>

<style>
/* @import "./assets/styles/normalize.css";
@import "./assets/styles/main.css"; */
@import '~@/assets/styles/normalize.css';
@import '~@/assets/styles/main.css';

.search-container {
    position: relative;
}

.search-button {
    position: absolute;
    top: 24px;
    right: 170px;
    font-size: 16px;
    font-weight: 700;
    transform: translateY(-50%);
    padding: 15px 15px;
    color: white;
    background-color: black;
    /* Adjust as needed */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.3);
}
</style>
