<template>
  <div v-if="!isPreview" class="related-btns">
    <router-link v-if="prevImportId"
      :to="{ path: '/' + countryCode + '/' + publicationName + '/' + encodeURIComponent(category) + '/' + prevImportId }"
      class="prev">
      &lsaquo;
    </router-link>
    <router-link v-if="nextImportId"
      :to="{ path: '/' + countryCode + '/' + publicationName + '/' + encodeURIComponent(category) + '/' + nextImportId }"
      class="next">
      &rsaquo;
    </router-link>
  </div>

  <div class="product-wrapper">
    <div class="product-container">
      <div v-if="countryCode !== 'RU'">
        <img v-if="product?.premium_icon && product?.premium_icon?.value"
          src="https://onlinedatabasetool.com/template-assets/img/icon-premium.png" class="icon-premium">
        <img v-if="product?.partner_icon && countryCode !== 'NO' && countryCode !== 'DK' && product?.partner_icon.value"
          src="https://onlinedatabasetool.com/template-assets/img/icon-partner.png" class="icon-premium">
        <img
          v-if="product?.pure_online_assortment_icon && product?.pure_online_assortment_icon?.value && countryCode !== 'NO' && countryCode !== 'DK'"
          src=" https://onlinedatabasetool.com/template-assets/img/PURE_ONLINE_ASSORTMENT_ICON.png"
          class="icon-premium">
        <img v-if="product?.non_sds_icon && product?.non_sds_icon.value && countryCode !== 'NO' && countryCode !== 'DK'"
          src="https://onlinedatabasetool.com/template-assets/img/NON_SDS_ICON.png" class="icon-premium">
        <img
          v-if="product?.weber_own_channels_icon && product?.weber_own_channels_icon.value && countryCode !== 'SE' && countryCode !== 'NO' && countryCode !== 'DK'"
          src="https://onlinedatabasetool.com/template-assets/img/WEBER_OWN_CHANNELS_ICON.png" class="icon-premium">
        <img
          v-if="product?.urban_electro_icon && product?.urban_electro_icon.value && countryCode !== 'SE' && countryCode !== 'NO' && countryCode !== 'DK'"
          src="https://onlinedatabasetool.com/template-assets/img/urban_electro_icon_round.png" width="30px"
          class="icon-premium">
        <img
          v-if="product?.weber_world_icon && product?.weber_world_icon.value && countryCode !== 'SE' && countryCode !== 'NO' && countryCode !== 'DK'"
          src="https://onlinedatabasetool.com/template-assets/img/WEBER_WORLD_ICON.png" width="30px"
          class="icon-premium">
      </div>


      <h1>
        {{ product?.product_title_line_1_accessories?.value }} <span v-if="product?.product_title_line_2_accessories">
          {{ product?.product_title_line_2_accessories?.value }}</span>
      </h1>
      <p>{{ product?.product_description?.value }}</p>

      <div class="product-img-wrapper">
        <div v-if="product?.smart_icon && !isNordic">
          <img v-if="product.smart_icon.value" src="https://onlinedatabasetool.com/template-assets/img/icon-smart.png"
            class="smart">
        </div>
        <div v-if="product?.fsc_icon && countryCode !== 'RU'">
          <img v-if="product.fsc_icon.value"
            src="https://onlinedatabasetool.com/template-assets/img/fsc_vrijstaand_icon.png" class="fsc">
        </div>
        <div v-if="product?.new_icon">
          <img v-if="product.new_icon.value"
            :src="'https://onlinedatabasetool.com/template-assets/img/translations/' + countryCode + '/NEW_icon.png'"
            class="new">
        </div>
        <div
          v-if="product?.special_icon && countryCode !== 'SE' && countryCode !== 'NO' && countryCode !== 'DK' && countryCode !== 'FI'">
          <img v-if="product.special_icon.value"
            src="https://onlinedatabasetool.com/template-assets/img/icon-special.png" class="special">
        </div>
        <div
          v-if="product?.pulse_icon && countryCode !== 'SE' && countryCode !== 'NO' && countryCode !== 'DK' && countryCode !== 'FI'">
          <img v-if="product.pulse_icon.value" src="https://onlinedatabasetool.com/template-assets/img/icon-pulse.png"
            class="special">
        </div>
        <img v-if="product?.stock_icon?.value" src="https://onlinedatabasetool.com/template-assets/img/icon-stock.png"
          class="product-image-icon icon-stock" id="icon-stock" width="52">
        <img
          :src="'https://onlinedatabasetool.com/template-assets/img/accessoires/' + product?.article_number_acc_sp_1?.value + '.jpg'"
          class="product-img">
      </div>

      <div class="article-number">
        <p v-if="product?.article_number_acc_sp_1?.label">
          <strong>{{ product?.article_number_acc_sp_1?.label }}</strong>

          {{ product?.article_number_acc_sp_1?.value }}<span
            v-if="product?.symbols_after_article_number_1">{{ product?.symbols_after_article_number_1?.value }} </span>
        </p>
        <img
          :src="'https://onlinedatabasetool.com/template-assets/img/barcodes/' + product?.barcode_number_acc_sp_1?.value + '.png'"
          alt="">
      </div>

      <div class="meta">
        <p>
          <span v-if="product?.amount_packaging?.label"><strong>{{ product.amount_packaging.label }}</strong>
            {{ product.amount_packaging.value }} <br></span>
          <span v-if="product?.amount_per_pallet?.label"><strong>{{ product.amount_per_pallet.label }}</strong>
            {{ product.amount_per_pallet.value }} <br></span>
          <!-- Dealer number, only for Nordics --->
          <span v-if="product?.dealer_number?.label && product?.dealer_number?.value">
            <strong>{{ product?.dealer_number.label }}</strong>
            {{ product?.dealer_number.value }} <br></span>

          <span v-if="product?.customs_code?.label && product?.customs_code?.value">
            <strong>{{ product.customs_code.label }}</strong>
            {{ product.customs_code.value }} <br></span>

          <!-- Suggested retail price, only for Switzerland --->
          <span v-if="isSwiss"><span
              v-if="product.suggested_retail_price_1.label"><strong>{{ product.suggested_retail_price_1.label }}</strong>
              {{ product.suggested_retail_price_1.value }} {{ product.symbols_after_suggested_retail_price.value }}
              <br></span></span>
        </p>
      </div>

      <div class="legal">
        <p>
          <span
            v-if="product?.additional_product_legal_text_1">{{ product?.additional_product_legal_text_1?.value }}<br></span>
          <span
            v-if="product?.additional_product_legal_text_2">{{ product?.additional_product_legal_text_2?.value }}</span>
        </p>
      </div>

      <div class="link-buttons">
        <div class="image-bank-url" v-if="product?.test_url.label && product?.test_url.value && !isSpread">
          <a class="image-bank-button" target="_blank" :href="product?.test_url.value">{{ product?.test_url.label }}</a>
        </div>

        <div class="image-bank-url"
          v-if="product?.test_url_2 && product?.test_url_2 && product?.test_url_2.value && !isSpread">
          <a class="image-bank-button" target="_blank"
            :href="product?.test_url_2.value">{{ product?.test_url_2.label }}</a>
        </div>
      </div>


    </div>
  </div>
</template>

<script>

export default {
  name: 'TemplateAccessory',
  props: {
    cataloglineApiData: {
      type: Object,
      required: true
    },
    countryCode: {
      type: String,
      required: true
    },
    publicationName: {
      type: String,
      required: true
    },
    isPreview: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    // Wat handige shortcuts om leesbaarheid template te vergroten
    product() {
      return this.cataloglineApiData?.product
    },
    category() {
      return this.$route.params.categoryName;
    },
    lineId() {
      return this.cataloglineApiData.id
    },
    prevImportId() {
      return this.cataloglineApiData?.prevImportId
    },
    nextImportId() {
      return this.cataloglineApiData?.nextImportId
    },
    isNordic() {
      return ['NO', 'SE', 'FI', 'DK'].includes(this.countryCode)
    },
    isSwiss() {
      return ['CHDE', 'CHFR'].includes(this.countryCode)
    }
  },
  created() {
    this.showHeader = true,
      this.showDownloadButton = true
  },
}
</script>
